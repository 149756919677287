const HOME = "/";
const DAPP = HOME + "dapp/";
const DAPP_HOME = DAPP + "home";
const DAPP_NFT_STAKING = DAPP + "nft-staking/";
const DAPP_NFT_STAKING_HOME = DAPP_NFT_STAKING + "home/";
const DAPP_NFT_STAKING_CREATE = DAPP_NFT_STAKING + "edit/:poolAddress";
const DAPP_NFT_STAKING_SINGLE = DAPP_NFT_STAKING + "project/:id";

const DAPP_ERC20_STAKING = DAPP + "erc20-staking/";
const DAPP_ERC20_STAKING_CREATE = DAPP_ERC20_STAKING + "edit/:poolAddress";
const DAPP_ERC20_STAKING_SINGLE = DAPP_ERC20_STAKING + "project/:id";

export const ROUTES_PATHS = {
  HOME,
  DAPP_BASE: `${DAPP}`,
  DAPP_HOME: `${DAPP_HOME}`,
  DAPP_NFT_STAKING: `${DAPP_NFT_STAKING}`,
  DAPP_NFT_STAKING_HOME: `${DAPP_NFT_STAKING_HOME}`,
  DAPP_NFT_STAKING_CREATE: `${DAPP_NFT_STAKING_CREATE}`,
  DAPP_NFT_STAKING_SINGLE: `${DAPP_NFT_STAKING_SINGLE}`,
  DAPP_ERC20_STAKING: `${DAPP_ERC20_STAKING}`,
  DAPP_ERC20_STAKING_CREATE: `${DAPP_ERC20_STAKING_CREATE}`,
  DAPP_ERC20_STAKING_SINGLE: `${DAPP_ERC20_STAKING_SINGLE}`,
};
