"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _IconComponents = require("./IconComponents");

Object.keys(_IconComponents).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _IconComponents[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _IconComponents[key];
    }
  });
});
Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "default", {
  enumerable: true,
  get: function get() {
    return _FeatherIcon["default"];
  }
});

var _FeatherIcon = _interopRequireDefault(require("./FeatherIcon"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}