import { Redirect, Route, Switch } from "react-router-dom";
import LoadingScreen from "./components/LoadingScreen";
import React, { Suspense, Fragment } from "react";
import SimpleLayout from "./layouts/SimpleLayout";
import { ROUTES_PATHS } from "./routes";

/**
 * @param routes[{path, component, exact, layout, routes, gard}]
 * @returns {JSX.Element}
 * @constructor
 */
export const renderRoutes = ({ routes = [] }) => {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Switch>
        {routes.map((route, i) => {
          const Guard = route.guard || Fragment;
          const Layout = route.layout || Fragment;
          const Component = route.component;

          return (
            <Route
              key={i}
              path={route.path}
              exact={route.exact}
              render={(props) => (
                <Guard>
                  <Layout>
                    {route.routes ? (
                      renderRoutes({ routes: route.routes })
                    ) : (
                      <Component {...props} />
                    )}
                  </Layout>
                </Guard>
              )}
            />
          );
        })}
      </Switch>
    </Suspense>
  );
};

export default renderRoutes;

export const appRoutes = [
  {
    exact: true,
    path: ROUTES_PATHS.HOME,
    component: () => {
      return <Redirect to={ROUTES_PATHS.DAPP_HOME} />;
    },
  },
  /*
             dapp it's better to render home in a route other than / to make use of layouts
             layout will not rerender on route change
             of course if you use anything other than hashrouter you will have to adjust your server
             to serve the index.html file for all routes
         */
  {
    path: ROUTES_PATHS.DAPP_BASE,
    layout: SimpleLayout, // React.lazy(() => import("./layouts/SimpleLayout")),
    routes: [
      {
        path: ROUTES_PATHS.DAPP_HOME,
        exact: true,
        component: React.lazy(() => import("./pages/Home")),
      },
      {
        path: ROUTES_PATHS.DAPP_NFT_STAKING_HOME,
        exact: true,
        component: React.lazy(() => import("./pages/NFTStaking/AllProjects")),
      },
      {
        path: ROUTES_PATHS.DAPP_NFT_STAKING_CREATE,
        exact: true,
        component: React.lazy(() => import("./pages/NFTStaking/EditPool")),
      },
      {
        path: ROUTES_PATHS.DAPP_NFT_STAKING_SINGLE,
        exact: true,
        component: React.lazy(() =>
          import("./pages/NFTStaking/SingleNftStakePage")
        ),
      },
      {
        path: ROUTES_PATHS.DAPP_ERC20_STAKING,
        exact: true,
        component: React.lazy(() => import("./pages/ERC20Staking/AllProjects")),
      },
      {
        path: ROUTES_PATHS.DAPP_ERC20_STAKING_CREATE,
        exact: true,
        component: React.lazy(() => import("./pages/ERC20Staking/EditPool")),
      },
      {
        path: ROUTES_PATHS.DAPP_ERC20_STAKING_SINGLE,
        exact: true,
        component: React.lazy(() => import("./pages/ERC20Staking/SinglePool")),
      },
      {
        path: ROUTES_PATHS.DAPP_NFT_STAKING,
        exact: false,
        routes: [
          {
            path: "*",
            component: () => {
              return <Redirect to={ROUTES_PATHS.DAPP_NFT_STAKING_HOME} />;
            },
          },
        ],
      },
      {
        path: "*",
        component: () => {
          return <Redirect to={ROUTES_PATHS.DAPP_HOME} />;
        },
      },
    ],
  },
];
